import React from "react"
import styled from "styled-components"

import SEO from "../../components/seo"
import PageWrapper from "../../styles/PageWrapper"
import Row from "../../styles/Row"
import { useTranslateValue } from "../../data/TranslateContext"

import Pick from "../front-page/HowItWorks/Pick"
import Peel from "../front-page/HowItWorks/Peel"
import Place from "../front-page/HowItWorks/Place"

const HowPageWrapper = styled(PageWrapper)`
  padding-top: 5rem;
  text-align: center;
`
const FlexRow = styled(Row)`
  position: relative;
  z-index: 20;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const TitleWrapper = styled(Row)`
  margin: 0 0 1rem;
  text-align: center;

  h1 {
    margin: 0;
  }

  p {
    font-style: italic;
    color: ${props => props.theme.color.primary};
  }
`

const TitleContent = props => {
  const [{ language }] = useTranslateValue()

  return (
    <TitleWrapper>
      {language === "en" && <h1>How to Blend a f'real</h1>}
      {language === "fr" && <h1>Comment mélanger un f'real</h1>}

      {language === "en" && (
        <p>
          With just three easy steps, you'll be sipping a f'real in no time!
        </p>
      )}
      {language === "fr" && (
        <p>
          En seulement trois étapes faciles, vous siroterez un f'real en un rien
          de temps!
        </p>
      )}
    </TitleWrapper>
  )
}

const HowItWorksPage = () => (
  <HowPageWrapper>
    <SEO title="How It Works" />
    <Row>
      <FlexRow>
        <TitleContent />
      </FlexRow>
      <FlexRow>
        <Pick />
        <Peel />
        <Place />
      </FlexRow>
    </Row>
  </HowPageWrapper>
)

export default HowItWorksPage
